import React, { useState } from "react";
import "../Css/PlanYourTour.css";
import Select from "../Components/PlanYourTour/Select";
import Data from "../database/data.json";
import { timeformat } from "../utils/timeformat";
import { priceformat } from "../utils/priceformat";
import Nav from "../Components/Layout/Nav";

type planets = "Mercury" | "Venus" | "Moon" | "Mars" | "Jupiter" | "Saturn" | "Uranus" | "Neptune";

// Mercury: Approximately 0.39 AU
// Venus: Approximately 0.72 AU
// Mars: Approximately 1.52 AU
// Jupiter: Approximately 5.20 AU
// Saturn: Approximately 9.58 AU
// Uranus: Approximately 19.22 AU
// Neptune: Approximately 30.05 AU
// 1 AU is the average distance from Earth to the Sun (about 93 million miles or 150 million kilometers
// speed = 700,000 miles per hour
// 1126540

const PlanYourTour = () => {
  const [destination, setDestination] = useState<string>("");
  const [poppedup, setPoppedUp] = useState<boolean>(false);
  const dataKeys: Array<string> = Object.keys(Data);

  return (
    <div className="plan">
      <Nav transparent={true} />
      <div className="head">Plan Your Tour</div>
      <div className="setup">
        <Select
          onpop={setPoppedUp}
          displayText="Choose your destination"
          onChange={setDestination}
          values={["Mercury", "Venus", "Moon", "Mars", "Jupiter", "Saturn", "Uranus", "Neptune"]}
        />
      </div>
      {!poppedup && dataKeys.includes(destination) ? (
        <div className="desc">
          <div className="data">
            <div>
              <div>
                <img src={require("../Assets/plan/price.png")} alt="Price" />
                Cost
              </div>
              {/* <div className="description">{Data[destination as planets].au * 69000000}$</div> */}
              <div className="description">${priceformat(Data[destination as planets].au * 69000000)}</div>
            </div>
            <div>
              <div>
                <img src={require("../Assets/plan/distance.png")} alt="DIstance" />
                Distance
              </div>
              <div className="description">{Data[destination as planets].au} AU</div>
            </div>
            <div>
              <div>
                <img src={require("../Assets/plan/calender.png")} alt="Time" />
                Time
              </div>
              <div className="description">{timeformat((Data[destination as planets].au * 150000000) / 112650)}</div>
            </div>
            <div>
              <div></div>
              <div></div>
            </div>
          </div>
          <div className="route">
            <div className="head">Route</div>
            <div className="routedata">{Data[destination as planets].route}</div>
          </div>
        </div>
      ) : !poppedup ? (
        <div className="voyager">
          <div className="head">Galactic Voyager</div>
          <div>
            The spacecraft in which we will be travelling is Galactic Voyager. The Galactic Voyager is an advanced spacecraft with a sleek, aerodynamic design optimized for both
            speed and efficiency. The spacecraft is powered by a state-of-the-art fusion propulsion system, which combines nuclear fusion and advanced ion propulsion technologies.
            This system allows the Galactic Voyager to achieve its maximum velocity of 700,000 miles per hour, making it one of the fastest spacecraft ever built. It uses a
            combination of deuterium and helium-3 as fuel for the fusion reaction, providing a nearly unlimited source of energy for the journey.Galactic Voyager is equipped with
            an advanced navigation system that relies on a network of GPS satellites and celestial navigation to precisely chart its course. Onboard computers continuously
            calculate the optimal trajectory and make mid-course corrections as needed, the spacecraft is designed to accommodate a crew of six astronauts, with spacious living
            quarters, a galley for preparing meals, and a recreation area for relaxation during the long interplanetary journeys. The crew members have access to advanced life
            support systems, ensuring their safety and well-being throughout the mission.
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default PlanYourTour;
