import React, { useEffect } from "react";
import "../Css/Jupiter.css";
import Nav from "../Components/Layout/Nav";
import { useLocation } from "react-router-dom";

const Jupiter = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  useEffect(() => {
    if (id) {
      document.getElementById(id)?.scrollIntoView();
    }
  }, [id]);

  return (
    <div className="jupiter">
      {/* nav  */}
      <Nav transparent={true} />
      {/* head  */}
      <div className="header">
        <div>
          <div className="text">JUPITER</div>
          <div className="desc">The majestic gas giant, dances with swirling storms and mesmerizing bands of vibrant colors, a celestial masterpiece in our cosmic symphony.</div>
        </div>
        <img src={require("../Assets/planets/jupiter.png")} alt="Jupiter" />
      </div>
      {/* about */}
      <div className="aboutHeader">ABOUT JUPITER</div>
      <div className="aboutDesc">
        <div>
          Jupiter, the celestial behemoth, dances in the cosmic ballet with an air of majestic grace. Its colossal presence commands attention, as its vibrant bands of swirling
          clouds paint a mesmerizing portrait of swirling artistry. This gas giant, adorned with a regal crown of storms, boasts the Great Red Spot, a tempestuous vortex that has
          raged for centuries. Jupiter's magnetic personality is undeniable, as it hosts a retinue of 79 moons, each with its own unique charm. From the icy allure of Europa to the
          volcanic fireworks of Io, these celestial companions add a touch of whimsy to Jupiter's grandeur. Oh, mighty Jupiter, ruler of the skies, your allure is simply out of
          this world.
        </div>
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/jupiter" title="Jupiter" />
      </div>
      {/* tourist destination  */}
      <div className="touristHeader">TOURISM IN JUPITER</div>
      <div className="touristDesc">
        <img src={require("../Assets/jupiter/jupiter.png")} alt="Tourism" />
        <div>
          Jupiter is not a desirable destination for visitors due to its inhospitable conditions. It consists mainly of gas, lacking any solid surface for spacecraft or individuals
          to land on. Any unfortunate spacecraft or person venturing through its vibrant clouds would face immediate destruction, leaving them with no escape. However, amidst the
          vast array of 95 known moons orbiting Jupiter, three of them hold great potential for exploration and tourism.
        </div>
      </div>
      {/* tourist destination  */}
      <div className="touristHeader1">
        <div>Aurora in ganymede</div>
      </div>
      <div className="touristDesc">
        <div>
          In the enchanting realm of Ganymede, a celestial dance unfolds under the watchful gaze of the magnetic field. As the moon twirls through the cosmos, ribbons of ethereal
          light ignite the heavens, painting the night sky with a kaleidoscope of colors. These mesmerizing auroras, born from electrified gas, encircle the moon's majestic north
          and south poles like celestial crowns. From emerald greens to sapphire blues, and fiery reds to golden hues, this cosmic spectacle captivates all who dare to witness its
          splendor. Ganymede's auroras, a testament to the moon's magnetic prowess, weave a tapestry of wonder, inviting stargazers to dream beyond the boundaries of the known
          universe.
        </div>
        <img src={require("../Assets/jupiter/aurora.png")} alt="Aurora" />
      </div>
      {/* tourist destination  */}
      <div className="touristHeader1" id="crater">
        <div>Capturing Crater of callisto</div>
      </div>
      <div className="touristDesc">
        <div>
          Callisto boasts an astonishing tally of 142 celestial craters, a cosmic treasure trove awaiting intrepid explorers. Embarking on this odyssey of discovery, one must brace
          for an epic voyage, for the sheer magnitude of these lunar scars demands time's patient embrace. Yet, fear not, for as you traverse this celestial canvas, capturing these
          enigmatic craters through the lens of your camera, a symphony of awe and wonder shall unfold. Each click immortalizes the dance of light and shadow, transforming mere
          pixels into a visual tapestry of cosmic artistry. So, embark on this celestial safari, where adventure and creativity converge, and let the craters become your muse.
        </div>
        <img src={require("../Assets/jupiter/crater.png")} alt="Crater" />
      </div>
      {/* tourist destination  */}
      <div className="touristHeader1">
        <div>Ice in Europa</div>
      </div>
      <div className="touristDesc">
        <div>
          Imagine a hidden world, concealed beneath a colossal icy shell, waiting to be explored. This extraordinary realm boasts a mysterious ocean, its depths stretching for 40
          to 100 miles. Picture yourself venturing into this frozen wonderland, traversing the icy terrain, and marveling at the ethereal beauty that surrounds you. As you delve
          deeper, the secrets of this enigmatic ocean unfold, revealing a mesmerizing ecosystem teeming with life. With each step, you uncover the untold stories of this hidden
          world, leaving footprints on the icy surface as a testament to your extraordinary journey. Prepare to embark on an adventure like no other, where the coolness of
          discovery intertwines with the creativity of imagination.
        </div>
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/europa" title="Europa" />
      </div>
      {/* tourist destination  */}
      <div className="touristHeader1" id="io">
        <div>Passing by Volaconos of io</div>
      </div>
      <div className="touristDesc">
        <div>
          Embarking on a daring escapade, we soar through the fiery realm of Io, where a symphony of volcanoes paints the celestial canvas. This enigmatic moon, adorned with
          hundreds of molten giants, proudly claims the title of the Solar System's most volcanic abode. With hearts pounding, we navigate our sleek spaceships through the
          treacherous labyrinth of fiery eruptions, dancing with danger and defying gravity. The scorching heat caresses our faces, igniting a thrill that only the brave can
          fathom. As we traverse this infernal playground, our spirits alight with the exhilaration of exploring the untamed wonders of the cosmos.
        </div>
        <img src={require("../Assets/jupiter/volcano.png")} alt="Volcano" />
      </div>
      <div className="touristHeader1">
        <div>Great gaint spot in Jupiter</div>
      </div>
      <div className="touristDesc">
        <div>
          Experience the awe-inspiring might of Jupiter's Great Red Spot, a colossal storm that dwarfs anything seen on Earth. This swirling tempest, over twice the size of our
          planet, captivates with its sheer magnitude and power. A must-see spectacle, this infamous spot beckons explorers to witness its mesmerizing beauty. Prepare to be
          astounded as you gaze upon this celestial hurricane, a testament to the grandeur of our universe. Embark on a journey to Jupiter and immerse yourself in the wonder of
          this great giant's most iconic feature.
        </div>
        <img src={require("../Assets/jupiter/spot.png")} alt="Spot" />
      </div>
      {/* facts  */}
      <div className="factsHeader">
        <div>FACTS ABOUT JUPITER</div>
      </div>
      <div className="factsDesc">
        <div>
          <li>
            Jupiter, the Boss of Planets, reigns supreme. With a mass more than twice that of all other planets combined, it holds an impressive title. The Great Spot on Jupiter is
            even as big as our Earth.
          </li>
          <li>
            Named after the King of the Roman Gods, Jupiter lives up to its godly reputation. It possesse s rings, although they are not easily visible. Compared to Venus, they
            pale in comparison.
          </li>
          <li>
            With your own eyes, you can witness its magnificence. As the third brightest object in the sky, it shines brightly, only surpassed by the moon and Venus. Mark your
            calendars for summer, the optimal time to observe this celestial wonder.
          </li>
          <li>
            Jupiter, our faithful ally, shield s Earth and the inner planets from harm. By diverting comets and asteroids, it performs a vital duty for mankind. Let us commend
            Jupiter for its exceptional service.
          </li>
        </div>
      </div>
    </div>
  );
};

export default Jupiter;
