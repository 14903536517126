import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "../Css/Mars.css";
import Nav from "../Components/Layout/Nav";

const Mars = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  useEffect(() => {
    if (id) {
      document.getElementById(id)?.scrollIntoView();
    }
  }, [id]);
  return (
    <div className="mars">
      <Nav transparent={true} />
      <div className="header">
        <div className="left">
          <div className="head">MARS</div>
          <div className="desc">Where red dust dances with the echoes of ancient secrets and the dreams of future pioneers.</div>
        </div>
        <img src={require("../Assets/mars/persons.png")} alt="Astronauts" />
      </div>

      {/* about */}
      <div className="aboutHeader">ABOUT MARS</div>
      <div className="aboutDesc">
        <div>
          Mars, the fourth planet from the Sun, is a captivating world that has long fascinated humanity. With its rusty red surface, it exudes an otherworldly charm. Vast canyons,
          towering volcanoes, and ancient riverbeds paint a picture of a planet that was once teeming with activity. The thin atmosphere, mostly composed of carbon dioxide, creates
          a surreal landscape where dust storms rage and the sky appears a hazy pink. The possibility of finding evidence of past or even present life on Mars adds to its allure.
          Mars, a planet of mystery and potential, beckons us to explore its secrets and unravel the enigma it holds.
        </div>
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/mars" title="Mars" />
      </div>

      {/* tourist destination  */}
      <div className="touristHeader">TOURISM IN SATURN</div>
      <div className="tourist1Desc">
        <img src={require("../Assets/mars/tourism.png")} alt="Tourism" />
        <div>
          Mars, the inhospitable realm, is not for the weak-willed. Its arid, rugged terrain and bone-chilling temperatures make it a formidable destination. Positioned as the
          fourth celestial body from the Sun, Mars stands alongside Venus as Earth's closest planetary companions. Gazing at the night sky, one cannot miss the striking sight of
          Mars, resembling a radiant crimson beacon. Embarking on a journey to this extraordinary planet is an awe-inspiring experience, for Mars is a world of stark
          contradictions. Its tourism offerings rival those of Earth, promising an adventure like no other. Brace yourself for an expedition that defies the limits of imagination.
        </div>
      </div>

      {/* tourist destination  */}
      <div className="touristHeader1">
        <div>RIDE TO NORTH POLE AND SOUTH POLE</div>
      </div>
      <div className="touristDesc">
        <div>
          The North and South Poles on Mars resemble Earth's polar regions, being the coldest areas on the planet. Winter temperatures can plummet to -150°C. Both poles possess ice
          caps primarily composed of water ice. One must go and explore this fascinating phenomenon.
        </div>
        <img src={require("../Assets/mars/mars.png")} alt="Mars" />
      </div>

      {/* tourist destination  */}
      <div className="touristHeader1">
        <div>FUN WITH KOROLEV FROZEN CRATER</div>
      </div>
      <div className="touristDesc">
        <div>
          Attention ice skaters! You will be thrilled to learn that there is a crater on Mars covered in ice that remains intact throughout the year, rather than just during
          certain seasons. This extraordinary phenomenon makes it an absolute must-visit destination for all ice enthusiasts. Don't miss out on this incredible opportunity to
          experience the joy of skating on another planet!
        </div>
        <img src={require("../Assets/mars/freezecrater.png")} alt="Crater" />
      </div>

      {/* tourist destination  */}
      <div className="touristHeader1" id="crater">
        <div>SMILE WITH GALLE CRATER</div>
      </div>
      <div className="touristDesc">
        <div>
          One of the most fascinating places to visit is Galle Crater, often referred to as the Happy Face Crater due to its obvious resemblance to a smiling face. This unique
          geological formation never fails to bring a smile to your face. Capture some amazing photos here and share them on your Instagram feed to spread the joy.
        </div>
        <img src={require("../Assets/mars/smilecrater.png")} alt="Galle Crater" />
      </div>

      {/* tourist destination  */}
      <div className="touristHeader1" id="volcano">
        <div>EXPLORING THARSIS VOLCANOES</div>
      </div>
      <div className="touristDesc">
        <div>
          Don't forget to witness the trio of enormous giants, which consists of three colossal shield volcanoes: Arsia Mons, Pavonis Mons, and Ascraeus Mons. These majestic
          formations are famously known as the Tharsis Montes and dominate approximately 25% of Mars' surface. Make sure to explore their grandeur and marvel at the wonders they
          hold.
        </div>
        <img src={require("../Assets/mars/volcano.png")} alt="Volcanoes" />
      </div>

      {/* tourist destination  */}
      <div className="touristHeader1">
        <div>JUMPING IN MARS</div>
      </div>
      <div className="touristDesc">
        <div>
          The gravity on this planet is 0.375 times that of Earth. Here, you can jump three times higher than on Earth. If you love high jumps, this is a must-visit place,
          especially for children.
        </div>
        <img src={require("../Assets/mars/jump.png")} alt="Jump" />
      </div>

      {/* facts  */}
      <div className="factsHeader">
        <div>FACTS ABOUT MARS</div>
      </div>
      <div className="factsDesc">
        <div>
          <li>
            The Red Planet: Many people mistakenly believe that Mars was once a battlefield for aliens. However, the red appearance of Mars is due to the oxidation of iron minerals
            in the Martian soil, giving the soil and atmosphere a red hue. It is important not to misunderstand this as a sign of bloodshed.
          </li>
          <li>
            Far from Home: Mars is located 140 million miles away from Earth. Therefore, it is crucial not to engage in any reckless activities that could result in being stranded
            on the planet. Help will not arrive quickly, as even the fastest spacecraft took between 155 and 128 days to reach Mars.
          </li>
          <li>
            Two Birthdays in a Year: A Martian day is slightly longer than an Earth day, and a Mars year is almost twice as long as an Earth year. If you were born on Mars, you
            would celebrate two birthdays annually.
          </li>
          <li>Mars is Relatively Small: It would take more than six Mars planets to match the volume of Earth.</li>
          <li>Duo of Moons: Mars has two moons, Phobos and Deimos. During the night, they appear as small, dotted stars in the sky.</li>
        </div>
      </div>
    </div>
  );
};

export default Mars;
