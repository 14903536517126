import React from "react";
import { Link } from "react-router-dom";

interface props {
  image: string;
  head: string;
  path: string;
}

const ExploreItem: React.FC<props> = (props) => {
  return (
    <Link to={props.path} className="exploreItem">
      <img src={props.image} alt="Earth" />
      <div className="head">{props.head}</div>
    </Link>
  );
};

export default ExploreItem;
