import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./Components/Layout/Layout";
import Home from "./Pages/Home";
import Error from "./Pages/Error";
import Team from "./Pages/Team";
import Moon from "./Pages/Moon";
import Venus from "./Pages/Venus";
import Saturn from "./Pages/Saturn";
import Mercury from "./Pages/Mercury";
import Mars from "./Pages/Mars";
import Uranus from "./Pages/Uranus";
import Neptune from "./Pages/Neptune";
import Jupiter from "./Pages/Jupiter";
import Earth from "./Pages/Earth";
import Sources from "./Pages/Sources";
import About from "./Pages/About";
import Liveview from "./Pages/Liveview";
import PlanYourTour from "./Pages/PlanYourTour";
import Gallery from "./Pages/Gallery";
import TrendingSites from "./Pages/TrendingSites";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/team" element={<Team />} />
          <Route path="/trending" element={<TrendingSites />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<Error />} />
        </Route>
        <Route path="/planyourtour" element={<PlanYourTour />} />
        <Route path="/mercury" element={<Mercury />} />
        <Route path="/venus" element={<Venus />} />
        <Route path="/earth" element={<Earth />} />
        <Route path="/moon" element={<Moon />} />
        <Route path="/mars" element={<Mars />} />
        <Route path="/jupiter" element={<Jupiter />} />
        <Route path="/saturn" element={<Saturn />} />
        <Route path="/uranus" element={<Uranus />} />
        <Route path="/neptune" element={<Neptune />} />
        <Route path="/sources" element={<Sources />} />
        <Route path="/liveview" element={<Liveview />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
