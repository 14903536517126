import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
interface props {
  displayText: string;
  values: Array<string>;
  onChange: Dispatch<SetStateAction<string>>;
  onpop: Dispatch<SetStateAction<boolean>>;
}

const Select: React.FC<props> = (props) => {
  const [items, setItems] = useState<Array<JSX.Element>>();
  const [active, setActive] = useState<string>(props.displayText);
  const [popped, setPopped] = useState<boolean>(false);
  const onPress = () => {
    if (popped) {
      setPopped(false);
      props.onpop(false);
    } else {
      setPopped(true);
      props.onpop(true);
    }
  };
  useEffect(() => {
    const onPre = (str: string) => {
      setPopped(false);
      props.onpop(false);
      setActive(str);
      props.onChange(str);
    };
    const tempArr: Array<JSX.Element> = [];
    for (let i = 0; i < props.values.length; i++) {
      tempArr.push(
        <div
          onClick={() => {
            onPre(props.values[i]);
          }}
          className="selectItem"
        >
          {props.values[i]}
        </div>
      );
    }
    setItems(tempArr);
  }, [props, props.values]);
  return (
    <div className="select">
      <div className={popped ? "active" : "act"} onClick={onPress}>
        <div>{active}</div>
        <img className={popped ? "dropdownrev" : "dropdown"} src={require("../../Assets/plan/down.png")} alt="Dropdown" />
      </div>
      <div className="items">{popped ? items : ""}</div>
    </div>
  );
};

export default Select;
