import React from "react";
import { Link } from "react-router-dom";

interface props {
  img: string;
  header: string;
  path: string;
}

const TrendingItem: React.FC<props> = (props) => {
  return (
    <Link to={props.path} className="item">
      <img src={props.img} alt="Trending Site" />
      <div className="header">{props.header}</div>
    </Link>
  );
};

export default TrendingItem;
