import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "../Css/Uranus.css";
import Nav from "../Components/Layout/Nav";
import UranusBox from "../Components/Planets/UranusBox";

const Uranus = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  useEffect(() => {
    if (id) {
      document.getElementById(id)?.scrollIntoView();
    }
  }, [id]);
  return (
    <div className="uranus">
      <Nav transparent={true} />
      <div className="header">
        <div className="left">
          <div className="head">URANUS</div>
          <div className="desc">Known for its unique blue-green color and distinctive sideways rotation.</div>
        </div>
        <img src={require("../Assets/uranus/uranus.png")} alt="Uranus" />
      </div>
      {/* about */}
      <div className="aboutHeader">ABOUT URANUS</div>
      <div className="aboutDesc">
        <div>
          Uranus is a unique and creative planet in our solar system. It is an ice giant, with a diameter that is four times that of Earth. As the seventh planet, it follows
          Saturn, also known as the "lord of the rings." Unlike Earth or Mars, Uranus does not have a solid surface. Instead, it is composed of icy liquid swirling around, with a
          dense atmosphere of methane. Due to its extreme cold, visitors must bring space suits to protect themselves. Uranus has 27 moons, which add to its beauty in the night
          sky. Additionally, it has13 rings, surpassing even Saturn. Unfortunately, Uranus is a lonely planet, as it has only been visited once by NASA's Voyager II spacecraft.
          Despite its cold and blue appearance, Uranus deserves more love and attention.
        </div>
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/uranus" title="Uranus" />
      </div>

      {/* tourist destination  */}
      <div className="touristHeader">TOURISM IN URANUS</div>
      <div className="touristDesc">
        <img src={require("../Assets/uranus/tourism.png")} alt="Uranus" />
        <div>
          While tourism to Uranus may seem far-fetched, there are still creative ways to attract tourists to this distant planet. One possibility is to establish a virtual reality
          experience that allows people to explore Uranus from the comfort of their own homes. This immersive experience could showcase the planet's stunning blue color, its unique
          ring system, and its mysterious atmosphere. Another idea is to create a space-themed amusement park that includes a simulated ride to Uranus. Visitors could strap into a
          spacecraft-like vehicle and experience the thrill of a journey through space, complete with stunning visuals and simulated zero gravity. These innovative approaches could
          spark interest in Uranus and inspire people to learn more about our solar system's enigmatic seventh planet.
        </div>
      </div>

      {/* boxes */}
      <div className="boxes">
        <UranusBox
          header="URANUS FLY BY"
          desc="Embark on an interstellar voyage aboard a spaceship, maintaining a secure distance from Uranus. Revel in the mesmerizing hues of its atmosphere and marvel at the ethereal beauty of its magnificent ring system."
        />
        <UranusBox
          header="FLOATING RESORT"
          desc="Escape to the ethereal beauty of a floating resort in the upper atmosphere of Uranus. Witness the mesmerizing weather patterns and vibrant cloud formations that paint the sky. Immerse yourself in a truly unique and unforgettable experience, where dreams become reality amidst the celestial wonders of the universe"
        />
        <UranusBox
          header="PHOTOGRAPHY"
          desc="Photography enthusiasts can explore Uranus and capture stunning images of its landscapes using a space camera. The challenge of gravity's acceleration offers a chance to experiment and enhance photography skills. Embrace the opportunity to showcase the beauty of Uranus like never before."
        />
        <UranusBox
          header="ICE SAILING"
          desc="Experience the thrill of ice sailing and ice skating on Uranus, gliding across its frozen surface and navigating through icy terrain. Create unforgettable memories and feel the rush of the cold climate, leaving you with a sense of wonder and adventure"
        />
      </div>

      {/* facts  */}
      <div className="factsHeader">
        <div>FACTS ABOUT URANUS</div>
      </div>
      <div className="factsDesc">
        <div>
          <li>It was the first planet to be discovered using a telescope by William Herschel in 1781.</li>
          <li>The planet has a total of 27 known moons, with the largest ones named Titania, Oberon, Umbriel, Ariel, and Miranda.</li>
          <li>Uranus has a very thin ring system, which was discovered in 1977. Unlike Saturn's prominent rings, Uranus' rings are dark and difficult to observe.</li>
          <li>The atmosphere of Uranus is composed mainly of hydrogen and helium, but it also contains traces of methane, which gives the planet its distinct blue-green color.</li>
          <li>Uranus experiences extreme seasons due to its unique axial tilt. Each pole experiences 42 years of continuous sunlight followed by 42 years of darkness.</li>
        </div>
      </div>
    </div>
  );
};

export default Uranus;
