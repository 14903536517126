import React from "react";
import TrendingItem from "../Components/Trending/TrendingItem";
import "../Css/Trending.css";

const TrendingSites = () => {
  return (
    <div className="trending">
      <TrendingItem path="/jupiter?id=crater" header="Crater of callisto" img={require("../Assets/jupiter/crater.png")} />
      <TrendingItem path="/jupiter?id=io" header="Volcanoes of io" img={require("../Assets/jupiter/volcano.png")} />
      <TrendingItem path="/saturn?id=titan" header="Titan's Methane Lake" img={require("../Assets/saturn/surfing.png")} />
      <TrendingItem path="/saturn?id=enceladus" header="Ice Volcano on Enceladus" img={require("../Assets/saturn/volcano.png")} />
      <TrendingItem path="/mars?id=crater" header="Galle Crater" img={require("../Assets/mars/smilecrater.png")} />
      <TrendingItem path="/mars?id=volcano" header="Tharsis Volcanoes" img={require("../Assets/mars/volcano.png")} />
      <TrendingItem path="/earth?id=canyon" header="Grand Canyon" img={require("../Assets/earth/canyon.png")} />
      <TrendingItem path="/earth?id=giza" header="Pyramids of Giza" img={require("../Assets/earth/pyramids.png")} />
      <TrendingItem path="/moon?id=carnival" header="Carnival on moon" img={require("../Assets/moon/carnival.png")} />
    </div>
  );
};

export default TrendingSites;
