import React from "react";

const Sources = () => {
  return (
    <div className="sources">
      https://img.favpng.com
      <br />
      https://w7.pngwing.com
      <br />
      https://cdn-icons-png.flaticon.com
      <br />
      https://icon2.cleanpng.com
      <br />
      https://png.pngtree.com
      <br />
      https://img.freepik.com
      <br />
      https://media.istockphoto.com
      <br />
      https://m.media-amazon.com
      <br />
      https://upload.wikimedia.org
      <br />
      https://www.3dcadbrowser.com
      <br />
      https://static.vecteezy.com
      <br />
      https://cdn.pixabay.com
      <br />
      https://thumbs.dreamstime.com
      <br />
      https://nssdc.gsfc.nasa.gov
      <br />
      https://www.solarsystemscope.com
      <br />
      https://149867481.v2.pressablecdn.com
      <br />
      https://eyes.nasa.gov
      <br />
      https://images.nasa.gov
      <br />
      https://science.nasa.gov
      <br />
      https://chat.openai.com
      <br />
      https://englishedit.ai
      <br />
      https://canva.me
      <br />
      https://clipchamp.com
      <br />
      https://freetools.textmagic.com
      <br />
    </div>
  );
};

export default Sources;
