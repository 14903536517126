import React from "react";

interface props {
  header: string;
  desc: string;
}

const UranusBox: React.FC<props> = (props) => {
  return (
    <div className="box">
      <div className="head">{props.header}</div>
      <div className="desc">{props.desc}</div>
    </div>
  );
};

export default UranusBox;
