import React from "react";
import { Link } from "react-router-dom";

interface props {
  image: string;
  head: string;
  desc: string;
  path: string;
}

const Planet: React.FC<props> = (props) => {
  return (
    <Link to={props.path} className="planet">
      <img src={props.image} alt="Earth" />
      <div className="body">
        <div className="head">{props.head}</div>
        <div className="desc">{props.desc}</div>
      </div>
    </Link>
  );
};

export default Planet;
