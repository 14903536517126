import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import Nav from "../Components/Layout/Nav";
import "../Css/Neptune.css";
import UranusBox from "../Components/Planets/UranusBox";

const Neptune = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  useEffect(() => {
    if (id) {
      document.getElementById(id)?.scrollIntoView();
    }
  }, [id]);
  return (
    <div className="neptune">
      <Nav transparent={true} />
      <div className="header">
        <div className="left">
          <div className="head">NEPTUNE</div>
          <div className="desc">The planet distinguished by its brilliant deep blue color and powerful storms.</div>
        </div>
        <img src={require("../Assets/planets/neptune.png")} alt="Neptune" />
      </div>

      {/* about */}
      <div className="aboutHeader">ABOUT NEPTUNE</div>
      <div className="aboutDesc">
        <div>
          Neptune is the eighth and farthest known planet from the Sun in our solar system. It is a gas giant, similar in composition to Uranus. Named after the Roman god of the
          sea, Neptune is known for its beautiful blue color, caused by the presence of methane in its atmosphere. It has a diameter of about 30,775 miles, making it the fourth
          largest planet in terms of size. Neptune has a complex weather system, with strong winds and storms, including the famous Great Dark Spot. It has a total of 14 known
          moons, the largest of which is Triton. Neptune was discovered in 1846 by French astronomer Urbain Le Verrier.
        </div>
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/neptune" title="Neptune" />
      </div>

      {/* tourist destination  */}
      <div className="touristHeader">TOURISM IN NEPTUNE</div>
      <div className="touristDesc">
        <img src={require("../Assets/neptune/neptune.png")} alt="Neptune" />
        <div>
          Neptune, the eighth and farthest known planet from the Sun, has long been a subject of fascination and intrigue for scientists and space enthusiasts alike. With its icy
          blue color, turbulent weather patterns, and mysterious composition, Neptune has captivated our imaginations and sparked countless questions about its potential for
          exploration and discovery. While the idea of tourism on Neptune may seem like a distant dream, advancements in space travel technology and our growing understanding of
          the planet's unique characteristics make it an intriguing possibility for the future.{" "}
        </div>
      </div>

      {/* boxes */}
      <div className="boxes">
        <UranusBox
          header="Neptune's Great Dark Spot"
          desc=" Similar to Jupiter's Great Red Spot, Neptune has a massive storm known as the Great Dark Spot .Those who are fascinated with jupiter's great spot, they won't be disappointed here .This could be a major sightseeing spot for you."
        />
        <UranusBox
          header="Set off with sun"
          desc="Given that Neptune's atmosphere scatters light, sunsets on this distant planet would look quite different from those on Earth. This could be a unique, once-in-a-lifetime experience .Enjoy your sunset view with us in our spaceship and having the mind blowing experience of the space."
        />
        <UranusBox
          header="Moon Tours"
          desc="Why to be limited to beauty of just the earth's moon. Lets explore something new. Neptune has 14 known moons, the largest of which is Triton. A moon-hopping tour could be an exciting adventure. Triton is the only moon that rotate in the opposite direction of its planets rotation. Have a view of the large frozen moon."
        />
        <UranusBox
          header="Virtual Reality Tourism"
          desc="Not everyone can physically reach destinations. And I guess that should not limit them .For those who can't physically visit Neptune due to its extreme conditions, virtual reality technology might offer a simulated experience of exploring the planet and its moons from the safety of Earth."
        />
      </div>

      {/* facts  */}
      <div className="factsHeader">
        <div>FACTS ABOUT NEPTUNE</div>
      </div>
      <div className="factsDesc">
        <div>
          <li>The only planet not visible by the naked eye. Its no wonder since its the farthest one too.</li>
          <li>The light reaching there is too dim. The planet is darker than your jokes.</li>
          <li>
            Among its 14 moons the largest is triton.Triton is the only large moon in the solar system that circles its planet in a direction opposite to the planet's rotation (a
            retrograde orbit), which suggests that it may once have been an independent object that Neptune captured
          </li>
          <li>
            Neptune has some of the most violent weather in the solar system, with the fastest winds reaching up to 1,500 miles per hour (2,400 kilometers per hour). It also
            experiences massive storms and dark spots, similar to Jupiter's Great Red Spot.You can fly without an aeroplane . But can't decide destination .
          </li>
          <li>Neptune has at least five main rings and four prominent ring arcs that we know of so far.The rings are thought to be relatively young and short-lived.</li>
          <li>Although being smaller than uranus, it have a greater mass. It the densest ice ball among the giant planets covered in ice.</li>
        </div>
      </div>
    </div>
  );
};

export default Neptune;
