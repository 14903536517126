import React from "react";
import "../Css/About.css";

const About = () => {
  return (
    <div className="about">
      <div className="container">
        <div className="text">Galactic Tour</div>
        <div className="desc">
          A website to solve all your question's about tourism possibilities on our solar system. Made by a group of highschool students from Bal Kalyan Vidya Mandir for the NASA
          Space Apps Challenge.
        </div>
      </div>
    </div>
  );
};

export default About;
