import React from "react";
import Nav from "./Nav";
import { Outlet } from "react-router-dom";
import "../../Css/Layout.css";

const Layout = () => {
  return (
    <div className="layout">
      <Nav transparent={false} />
      <Outlet />
      {/* <div className="c">ⓒTeam Benzene</div> */}
    </div>
  );
};

export default Layout;
