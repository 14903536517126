import React from "react";
import "../Css/Home.css";
import Planets from "../Components/Home/Planets";
import Explore from "../Components/Home/Explore";

const Home = () => {
  return (
    <div className="home">
      <div className="container2">
        <div>
          <div className="head">Galactic Tour</div>
          <div className="desc">
            Welcome to Galactic Tour, your passport to the wonders of our solar system! Prepare to embark on a cosmic adventure like no other, as we unveil the infinite
            possibilities of tourism beyond Earth's boundaries. Our mission is to ignite your curiosity, fuel your imagination, and guide you through the mesmerizing celestial
            destinations that await your exploration. From the ancient mysteries of Mars to the breathtaking vistas of Saturn's rings, from the volcanic landscapes of Io to the icy
            depths of Neptune, Galactic Tour is your portal to understanding, planning, and experiencing the wonders of our celestial neighborhood.
          </div>
        </div>
        <iframe src="https://eyes.nasa.gov/apps/solar-system/#/home" title="Jupiter" />
      </div>
      <Planets />

      <Explore />
    </div>
  );
};

export default Home;
