import React from "react";
import Planet from "./Planet";

const Planets = () => {
  return (
    <div className="container">
      <div className="head">Click on a planet</div>
      <div className="planets">
        <Planet path="/mercury" image={require("../../Assets/planets/mercury.png")} head="Mercury" desc="Closest planet to the sun. It might be a little bit hot out there." />
        <Planet path="/venus" image={require("../../Assets/planets/venus.png")} head="Venus" desc="We call it the Morning and Evening Star, It's too bright!" />
        <Planet path="/earth" image={require("../../Assets/planets/earth.png")} head="Earth" desc="Earth would be the planet you want to visit if you are an alien." />
        <Planet path="/moon" image={require("../../Assets/planets/moon.png")} head="Moon" desc="The only place humans have stepped feet on except the Earth." />
        <Planet path="/mars" image={require("../../Assets/planets/mars.png")} head="Mars" desc="There's a slight chance humans can survive on this planet." />
        <Planet path="/jupiter" image={require("../../Assets/planets/jupiter.png")} head="Jupiter" desc="The largest planet in the solar system, 1300 Earths can fit inside it." />
        <Planet path="/saturn" image={require("../../Assets/planets/saturn.png")} head="Saturn" desc="The planets which is famous just because of it's rings." />
        <Planet path="/uranus" image={require("../../Assets/planets/uranus.png")} head="Uranus" desc="You could swim on this planet. Also the name is funny, isn't it?" />
        <Planet path="/neptune" image={require("../../Assets/planets/neptune.png")} head="Neptune" desc="Apparently it's an ice giant, best for all those ice eaters." />
      </div>
    </div>
  );
};

export default Planets;
