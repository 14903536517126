import React from "react";

interface props {
  image: string;
  name: string;
  role: string;
  username: string;
}

const Member: React.FC<props> = (props) => {
  return (
    <div className="member">
      <img src={props.image} alt={props.name} />
      <div className="name">{props.name}</div>
      <div className="role">{props.role}</div>
      <div className="insta">
        <img src={require("../../Assets/insta.png")} alt="Instagram" />
        <div>{props.username}</div>
      </div>
    </div>
  );
};

export default Member;
