import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import MercuryBox from "../Components/Planets/MercuryBox";
import "../Css/Mercury.css";
import Nav from "../Components/Layout/Nav";

const Mercury = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  useEffect(() => {
    if (id) {
      document.getElementById(id)?.scrollIntoView();
    }
  }, [id]);
  return (
    <div className="mercury">
      <Nav transparent={true} />
      {/* Head  */}
      <div className="head">
        <div className="left">
          <div className="header">Mercury</div>
          <div className="desc">The planet for sun bath</div>
          <div className="bottom">
            Welcome to the beautiful and mysterious planet of Mercury, where the scorching sunsets and endless craters will leave you in awe. Explore its barren landscapes and
            marvel at its unique lack of atmosphere. Prepare for an extraordinary journey unlike any other in our solar system.
          </div>
        </div>
        <img src={require("../Assets/mercury/mercury.png")} alt="Mercury" />
      </div>
      {/* desc  */}
      <div className="desc">
        Cratered, contoured with hills, and covered with dark dust, Mercury's landscape certainly looks a lot like the moon's. And don't yell, "What a big sun!" Here, the sun
        appears three times larger in the sky than it does when viewed from Earth. You're standing on the closest planet to the sun. Don't forget your sunglasses and enjoy the
        ride.
      </div>
      {/* explore  */}
      <div className="explore">
        <div className="header">Is Mercury Explorable?</div>
        <div className="bottom">
          <div className="desc">
            Exploring Mercury is an arduous task, given its scorching temperatures. However, conquering this obstacle is not impossible. The true challenge lies in finding a safe
            landing method. With no atmosphere to serve as a natural brake, reliance on heavy fuels becomes crucial to control speed. These hurdles may seem insurmountable, but
            mankind has a remarkable track record of triumphing over countless challenges. Humans possess an extraordinary ability to transcend boundaries, delving into uncharted
            realms and returning with newfound possibilities. Let us dare to dream of the potential for tourism on Mercury, for who knows what incredible feats we can achieve when
            we set our minds to it.
          </div>
          <iframe src="https://eyes.nasa.gov/apps/orrery/#/mercury" title="Mercury" />
        </div>
      </div>
      {/* discover  */}
      <div className="discover">
        <div className="header">Discover on Mercury</div>
        <div className="boxes">
          <MercuryBox image={require("../Assets/mercury/Picture2.jpg")} desc="Test your Photography skill under zero gravity" />
          <MercuryBox image={require("../Assets/mercury/Picture3.jpg")} desc="The crater which can fit the whole texas in it" />
          <MercuryBox image={require("../Assets/mercury/Picture4.jpg")} desc="The beautiful view of auroras in mercury" />
          <MercuryBox
            title="Capture The Mercury"
            image={require("../Assets/mercury/Picture5.jpg")}
            desc="Lets see if your photography skill is affected by the acceleration due to gravity. Lets see , What can you capture at zero gravity. Its all up to you."
          />
          <MercuryBox
            title="Exploring caloris basin"
            image={require("../Assets/mercury/Picture6.png")}
            desc="It would be pretty interesting to view a crater which can fit entire state of texas.Caloris basin has been flooded by lava that gives it beautiful sight of orange.it is must to take one glimpse of it while visiting mercury."
          />
          <MercuryBox
            title="Aurora in Mercury"
            image={require("../Assets/mercury/Picture7.jpg")}
            desc="Mercury has a very thin atmosphere and auroras on the planet are created when solar wind, the stream of particles, interact directly with the planet's surface. It will remind you of the auroras back home on Earth, but it diffusely covers the whole sky and is brightest toward the horizon."
          />
        </div>
      </div>
      {/* facts  */}
      <div className="facts">
        <div className="header">FACTS ABOUT MERCURY</div>
        <div className="facts">
          <div className="fact">
            <div className="head">Mercury named after swift god</div>
            <div className="body">Mercury is named after swift footed roman messenger god.it is fastest planet, well usain bolt has got some competiton here.</div>
          </div>
          <div className="fact">
            <div className="head">Spaceship with ceramic cloth</div>
            <div className="body">NASA's Messenger spacecraft, which has been orbiting Mercury since 2011, carries a heavy-duty sunshade made from ceramic cloth.</div>
          </div>
          <div className="fact">
            <div className="head">Mercury as two different things</div>
            <div className="body">In ancient time mercury was mistakenly thought of being two different objects in sky , the morning and evening star.</div>
          </div>
          <div className="fact">
            <div className="head">Mercury's Atmosphere</div>
            <div className="body">Mercury's atmosphere, a mere whisper in space, envies Venus' thick and vibrant embrace..</div>
          </div>
          <div className="fact">
            <div className="head">Mercury as a solitary star</div>
            <div className="body">In the vast expanse of space, Mercury dances alone, a solitary star in the dark cosmic ballet.</div>
          </div>
          <div className="fact">
            <div className="head">Magnetic tornadoes</div>
            <div className="body">Mercury's magnetic tornadoes are both simple and cool, but it's important to be cautious when venturing there in metal suits.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mercury;
