import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "../Css/Saturn.css";
import Nav from "../Components/Layout/Nav";

const Saturn = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  useEffect(() => {
    if (id) {
      document.getElementById(id)?.scrollIntoView();
    }
  }, [id]);
  return (
    <div className="saturn">
      {/* nav  */}
      <Nav transparent={true} />

      {/* head  */}
      <div className="header">
        <img src={require("../Assets/saturn/saturn.png")} alt="Saturn" />
        <div>
          <div className="text">SATURN</div>
          <div className="desc">A celestial beauty adorned with rings and moons, dances as the sixth planet in our solar symphony</div>
        </div>
      </div>

      {/* about */}
      <div className="aboutHeader">ABOUT SATURN</div>
      <div className="aboutDesc">
        <div>
          Saturn, the second largest planet in our solar system, is a fascinating celestial body. Its diameter is so vast that you could fit nine Earths in a line to match it.
          Positioned sixth from the sun, it follows the mighty Jupiter. However, don't expect to set foot on Saturn, as it lacks a solid surface. Instead, it is a colossal gas
          ball, composed mainly of hydrogen and helium. If you were hoping for a breath of fresh air, think again. Saturn's atmosphere offers no respite. With a staggering 146
          moons, it reigns as the king of satellites, leaving poor Mercury envious. And let's not forget its magnificent rings, which I like to call the lord of the rings. Engaged
          in a mesmerizing dance, they captivate the attention of all other planets. Saturn truly is a distant and captivating destination, with only one of the four missions sent
          to it successfully orbiting.
        </div>
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/saturn" title="Saturn" />
      </div>

      {/* tourist destination  */}
      <div className="touristHeader">TOURISM IN SATURN</div>
      <div className="touristDesc">
        <img src={require("../Assets/saturn/saturn2.png")} alt="Saturn" />
        <div>
          Imagine embarking on a journey to Saturn, a planet shrouded in mystery and beauty. As you approach, its rings mesmerize you, sparkling like a celestial jewel. Your
          spacecraft glides effortlessly through space, offering breathtaking views of Saturn's diverse moons. You marvel at Enceladus, with its icy geysers, and Titan, with its
          hazy orange atmosphere. The challenges of this interplanetary tourism are immense, but not insurmountable. Advanced technology and innovative spacecraft designs make the
          journey possible. As you step foot on these distant worlds, you become part of a new era of space exploration, where the wonders of the universe are no longer confined to
          science fiction, but are within reach of adventurous travelers.
        </div>
      </div>

      {/* tourist destination  */}
      <div className="touristHeader1">
        <div>Enceladus Dive</div>
      </div>
      <div className="touristDesc">
        <div>
          Imagine the thrill of plunging into the depths of Enceladus, a moon teeming with potential life. As you descend through the icy crust, your heart races with anticipation.
          The darkness gives way to a mesmerizing world of liquid water, illuminated by bioluminescent organisms. Strange creatures, unlike anything seen before, gracefully glide
          past you. Their vibrant colors and intricate forms leave you in awe. With each passing moment, you realize the magnitude of this discovery. You are witnessing the birth
          of a new chapter in human history, forever changing our understanding of life in the universe.
        </div>
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/enceladus" title="Enceladus" />
      </div>

      {/* tourist destination  */}
      <div className="touristHeader1">
        <div>Titan Trek</div>
      </div>
      <div className="touristDesc">
        <div>
          Embark on an extraordinary adventure through the frozen wonderland of Titan, Saturn's largest moon. Titan Trek offers a unique opportunity to explore its ethereal
          landscape, adorned with methane lakes and rivers. As you traverse this alien terrain, you'll be captivated by the organic-rich chemistry that mirrors our own planet. With
          a thick atmosphere enveloping you, the experience will be both surreal and awe-inspiring. Don't miss the chance to witness the beauty of Titan up close and personal, but
          remember to pack your cold-weather gear to withstand the frigid temperatures. Titan Trek promises an unforgettable journey into the unknown.
        </div>
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/titan" title="Titan" />
      </div>

      {/* tourist destination  */}
      <div className="touristHeader1">
        <div>Saturn's Rings Flyby</div>
      </div>
      <div className="touristDesc">
        <div>
          Saturn's rings, a celestial masterpiece, beckon you to witness their ethereal beauty. As you venture closer, a mesmerizing dance of ice and rock particles unfolds before
          your eyes. Each glimmering fragment tells a story of cosmic wonder, captivating your senses. The sheer magnitude of this spectacle leaves you in awe, as you realize the
          vastness of the universe. It's a moment that transcends time and space, igniting your imagination and reminding you of the infinite possibilities that exist beyond our
          earthly realm. Prepare to be enchanted, for this encounter with Saturn's rings will forever leave an indelible mark on your soul..
        </div>
        <img src={require("../Assets/saturn/rocket.png")} alt="Rocket" />
      </div>

      {/* tourist destination  */}
      <div className="touristHeader1" id="titan">
        <div>Titan's Methane Lake Surfing</div>
      </div>
      <div className="touristDesc">
        <div>
          Experience the thrill of surfing like never before on Titan, where lakes brimming with liquid methane await. Brace yourself for an extraordinary adventure, but don't
          forget to don a specialized suit capable of withstanding the bone-chilling cold. Glide through the ethereal waves, feeling the rush of adrenaline as you navigate this
          alien landscape. Titan's unique environment offers a creative and simple escape from the ordinary, allowing you to immerse yourself in a world unlike any other. So, grab
          your board, embrace the unknown, and let the icy waves of Titan carry you to new heights of excitement.
        </div>
        <img src={require("../Assets/saturn/surfing.png")} alt="Surfing" />
      </div>

      {/* tourist destination  */}
      <div className="touristHeader1" id="enceladus">
        <div>Ice Volcano Visit on Enceladus</div>
      </div>
      <div className="touristDesc">
        <div>
          Enceladus, one of Saturn's moons, offers a unique and mesmerizing experience with its ice volcanoes. Imagine witnessing water shooting out from the moon's surface,
          creating a breathtaking display of nature's power. This extraordinary phenomenon, caused by geothermal activity, is a sight that will leave you in awe. The simplicity of
          this experience lies in its natural beauty, reminding us of the wonders that exist beyond our own planet. So, why not embark on a journey to Enceladus and immerse
          yourself in the creative forces of the universe? It's an adventure that will forever be etched in your memory.
        </div>
        <img src={require("../Assets/saturn/volcano.png")} alt="Ice Volcano" />
      </div>

      {/* facts  */}
      <div className="factsHeader">
        <div>FACTS ABOUT SATURN</div>
      </div>
      <div className="factsDesc">
        <div>
          <div>
            <li>Saturn is the second-largest planet in our solar system, with a diameter about nine times that of Earth.</li>
            <li>Despite its size, Saturn is remarkably lightweight and less dense than water. If you could find a bathtub large enough, Saturn would float!</li>
            <li>At Saturn's north pole, there's a bizarre hexagonal-shaped storm system that has puzzled scientists for years.</li>
            <li>
              The exact origins of Saturn's rings are still debated, but they may be remnants of a moon that was shattered by tidal forces or a comet that got too close to the
              planet.
            </li>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Saturn;
