import React from "react";
import { Link, useLocation } from "react-router-dom";

interface props {
  name: string;
  paths: Array<string>;
}

const NavItem: React.FC<props> = (props) => {
  const location = useLocation();
  const path = location.pathname;
  return (
    <Link className={props.paths.includes(path) ? "linkactive" : "link"} to={props.paths[0]}>
      {props.name}
    </Link>
  );
};

export default NavItem;
