import React from "react";
import "../Css/Gallery.css";

const Gallery = () => {
  return (
    <div className="gallery">
      <div className="container">
        <img alt="Gallery" src={require("../Assets/gallery/1.jpg")} />
        <img alt="Gallery" src={require("../Assets/gallery/2.webp")} />
        <img alt="Gallery" src={require("../Assets/gallery/3.webp")} />
        <img alt="Gallery" src={require("../Assets/gallery/4.jpg")} />
        <img alt="Gallery" src={require("../Assets/gallery/5.jpg")} />
        <img alt="Gallery" src={require("../Assets/gallery/6.jpg")} />
        <img alt="Gallery" src={require("../Assets/gallery/7.jpg")} />
        <img alt="Gallery" src={require("../Assets/gallery/8.jpg")} />
        <img alt="Gallery" src={require("../Assets/gallery/9.jpg")} />
        <img alt="Gallery" src={require("../Assets/gallery/10.jpg")} />
        <img alt="Gallery" src={require("../Assets/gallery/11.jpg")} />
        <img alt="Gallery" src={require("../Assets/gallery/12.jpg")} />
        <img alt="Gallery" src={require("../Assets/gallery/13.jpg")} />
        <img alt="Gallery" src={require("../Assets/gallery/14.jpg")} />
        <img alt="Gallery" src={require("../Assets/gallery/15.jpg")} />
        <img alt="Gallery" src={require("../Assets/gallery/16.jpg")} />
        <img alt="Gallery" src={require("../Assets/gallery/17.jpg")} />
      </div>
    </div>
  );
};

export default Gallery;
