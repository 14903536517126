import Nav from "../Components/Layout/Nav";
import "../Css/Earth.css";
import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const Earth = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  useEffect(() => {
    if (id) {
      document.getElementById(id)?.scrollIntoView();
    }
  }, [id]);
  return (
    <div className="earth">
      {/* nav  */}
      <Nav transparent={true} />
      {/* head  */}
      <div className="header">
        <div>
          <div className="text">EARTH</div>
          <div className="desc">The cosmic canvas where life's vibrant hues intertwine with nature's symphony, painting a masterpiece of existence</div>
        </div>
        <img src={require("../Assets/earth/earth.png")} alt="Earth" />
      </div>
      {/* about */}
      <div className="aboutHeader">ABOUT EARTH</div>
      <div className="aboutDesc">
        <div>
          Earth is the third planet from the Sun and the only known celestial body to support life. It has a diverse range of ecosystems, including oceans, forests, deserts, and
          mountains. The planet is approximately 4.5 billion years old and has a diameter of about 12,742 kilometers. Earth's atmosphere is composed mainly of nitrogen and oxygen,
          providing the necessary conditions for life to thrive. It has a natural satellite, the Moon, which affects tides and provides light at night. Earth is home to millions of
          species, including humans, who have significantly impacted the planet through industrialization and climate change.
        </div>
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/earth" title="Earth" />
      </div>
      {/* tourist destination  */}
      <div className="touristHeader">TOURISM IN EARTH</div>
      <div className="touristDesc">
        <img src={require("../Assets/earth/tourism.png")} alt="Tourism" />
        <div>
          Earth, a mesmerizing and diverse planet, offers an extraordinary experience for extraterrestrial tourists. With its breathtaking landscapes, from the majestic mountains
          to the vast oceans, Earth provides a visual feast for any visitor. The planet's rich biodiversity, with countless species of flora and fauna, is a sight to behold.
          Moreover, Earth's vibrant cultures and civilizations, each with their unique traditions and histories, offer an immersive cultural experience. From the bustling cities to
          the serene countryside, aliens can explore a wide range of human lifestyles. With its remarkable beauty and captivating diversity, Earth is an enticing destination for
          any curious extraterrestrial traveler.
        </div>
      </div>
      {/* tourist destination  */}
      <div className="touristHeader1">
        <div>THE GREAT BARRIER REEF</div>
      </div>
      <div className="touristDesc">
        <div>
          A mesmerizing underwater wonderland, the Great Barrier Reef is a kaleidoscope of colors and shapes that would leave any extraterrestrial being in awe. With its vibrant
          coral formations and an array of marine life, this alien hotspot offers a chance to explore a world beneath the waves like no other. From swimming alongside majestic sea
          turtles to encountering the elusive clownfish, this aquatic paradise is a must-visit for any intergalactic traveler seeking a glimpse into the mysteries of Earth's
          oceans.
        </div>
        <img src={require("../Assets/earth/reef.png")} alt="Reef" />
      </div>
      {/* tourist destination  */}
      <div className="touristHeader1" id="canyon">
        <div>THE GRAND CANYON</div>
      </div>
      <div className="touristDesc">
        <div>
          Picture a colossal chasm carved by the hands of time, stretching as far as the eye can see. The Grand Canyon, a geological masterpiece, is a sight that would leave even
          the most advanced alien species humbled. With its towering cliffs, layers of vibrant rock formations, and the mighty Colorado River snaking through its depths, this
          natural wonder offers a glimpse into the immense power and beauty of Earth's geological history. A visit to the Grand Canyon is an invitation to witness the grandeur of
          our planet's ancient past.
        </div>
        <img src={require("../Assets/earth/canyon.png")} alt="Canyon" />
      </div>
      {/* tourist destination  */}
      <div className="touristHeader1">
        <div>THE NORTHERN LIGHTS</div>
      </div>
      <div className="touristDesc">
        <div>
          Imagine a celestial dance of vibrant colors illuminating the night sky. The Northern Lights, also known as the Aurora Borealis, is a breathtaking phenomenon that would
          captivate any extraterrestrial visitor. With its ethereal curtains of green, purple, and pink swirling across the heavens, this cosmic light show is a testament to the
          wonders of Earth's magnetic field. From the Arctic regions, where these celestial ribbons are most commonly observed, aliens can witness a mesmerizing display that
          transcends the boundaries of imagination.
        </div>
        <img src={require("../Assets/earth/lights.png")} alt="lights" />
      </div>
      {/* tourist destination  */}
      <div className="touristHeader1">
        <div>THE SERENGETI MIGRATION</div>
      </div>
      <div className="touristDesc">
        <div>
          Picture a vast expanse of golden savannah, teeming with life and movement. The Serengeti Migration, a spectacle of epic proportions, is a must-see for any alien seeking a
          front-row seat to one of Earth's greatest natural events. Witness millions of wildebeest and zebras embarking on a treacherous journey across the African plains, braving
          crocodile-infested rivers and evading predators in a quest for greener pastures. This awe-inspiring migration showcases the resilience and interconnectedness of Earth's
          ecosystems, leaving aliens in awe of the delicate balance of life on our planet.
        </div>
        <img src={require("../Assets/earth/savana.png")} alt="Savana" />
      </div>
      {/* tourist destination  */}
      <div className="touristHeader1">
        <div>THE GREAT WALL OF CHINA</div>
      </div>
      <div className="touristDesc">
        <div>
          Imagine a colossal dragon winding its way across the rugged Chinese landscape. The Great Wall of China, a testament to human ingenuity and perseverance, is a sight that
          would leave any alien visitor marveling at our architectural prowess. Stretching over 13,000 miles, this ancient fortification offers a glimpse into the rich history and
          cultural heritage of Earth's civilizations. From its watchtowers, aliens can survey vast landscapes, imagining the stories of emperors, warriors, and the countless lives
          that shaped this monumental structure.
        </div>
        <img src={require("../Assets/earth/greatwall.png")} alt="Great Wall" />
      </div>
      {/* tourist destination  */}
      <div className="touristHeader1" id="giza">
        <div>THE PYRAMIDS OF GIZA</div>
      </div>
      <div className="touristDesc">
        <div>
          Imagine colossal structures rising from the desert sands, shrouded in mystery and ancient wisdom. The Pyramids of Giza, a testament to human ambition and architectural
          brilliance, are a must-visit for any alien seeking a glimpse into Earth's ancient civilizations. These towering monuments, built thousands of years ago, continue to defy
          the passage of time, leaving visitors in awe of the precision and engineering marvels of our ancestors. From the Great Pyramid, aliens can ponder the secrets of the past,
          marvel at the alignment with celestial bodies, and contemplate the enigmatic allure of these ancient tombs.
        </div>
        <img src={require("../Assets/earth/pyramids.png")} alt="Pyramids" />
      </div>
      {/* tourist destination  */}
      <div className="touristHeader1">
        <div>MT EVEREST</div>
      </div>
      <div className="touristDesc">
        <div>
          Mt. Everest, the majestic peak standing tall at 29,032 feet, has captivated the imagination of humans for centuries. However, in a surprising turn of events, it has now
          become a major attraction for intergalactic tourism. Aliens from distant galaxies flock to this extraordinary mountain, drawn by its sheer beauty and challenging terrain.
          With advanced technology, these extraterrestrial beings are able to effortlessly scale its treacherous slopes, providing them with a unique and exhilarating experience.
          The breathtaking views from the summit, coupled with the sense of accomplishment, make Mt. Everest an irresistible destination for alien tourists seeking adventure and a
          connection with Earth's natural wonders.
        </div>
        <img src={require("../Assets/earth/everest.png")} alt="Everest" />
      </div>
      {/* facts  */}
      <div className="factsHeader">
        <div>FACTS ABOUT EARTH</div>
      </div>
      <div className="factsDesc">
        <div>
          <li>Earth is the only known planet with a diverse range of ecosystems, from lush rainforests to vast deserts, making it a true haven for life's wonders.</li>
          <li>
            Earth's magnetic field, generated by the swirling molten iron in its core, protects us from harmful solar radiation and guides migratory animals across the globe.
          </li>
          <li>With its majestic mountains, breathtaking waterfalls, and mesmerizing auroras, Earth is a celestial masterpiece that never ceases to amaze and inspire.</li>
        </div>
      </div>
    </div>
  );
};

export default Earth;
