import React from "react";
import Nav from "../Components/Layout/Nav";
import "../Css/Liveview.css";

const Liveview = () => {
  return (
    <div className="liveview">
      <Nav transparent={false} />
      <div className="container">
        <iframe src="https://eyes.nasa.gov/apps/solar-system/#/home" title="Solar System" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/mercury" title="Jupiter" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/venus" title="Jupiter" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/earth" title="Jupiter" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/moon" title="Jupiter" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/mars" title="Jupiter" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/phobos" title="Jupiter" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/deimos" title="Jupiter" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/jupiter" title="Jupiter" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/europa" title="Jupiter" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/ganymede" title="Jupiter" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/callisto" title="Jupiter" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/saturn" title="Jupiter" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/titan" title="Jupiter" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/enceladus" title="Jupiter" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/uranus" title="Jupiter" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/titania" title="Jupiter" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/neptune" title="Jupiter" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/sc_voyager_1" title="Jupiter" />
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/sc_voyager_2" title="Jupiter" />
      </div>
    </div>
  );
};

export default Liveview;
