import React from "react";

interface props {
  title?: string;
  image: string;
  desc: string;
}

const MercuryBox: React.FC<props> = (props) => {
  return (
    <div className="box">
      {props.title ? <div className="title">{props.title}</div> : ""}
      <img src={props.image} alt="Discover" />
      <div className="desc">{props.desc}</div>
    </div>
  );
};

export default MercuryBox;
