import React from "react";
import ExploreItem from "./ExploreItem";

const Planets = () => {
  return (
    <div className="container">
      <div className="head">Explore</div>
      <div className="planets">
        <ExploreItem head="Plan Your Tour" image={require("../../Assets/explore/spaceship.jpg")} path="/planyourtour" />
        <ExploreItem head="Trending Sites" image={require("../../Assets/explore/astronaut.png")} path="/trending" />
        <ExploreItem head="Gallery" image={require("../../Assets/explore/gallery.avif")} path="/gallery" />
        <ExploreItem head="Liveview" image={require("../../Assets/explore/sattelite.jpg")} path="/liveview" />
      </div>
    </div>
  );
};

export default Planets;
