import React from "react";
import NavItem from "./NavItem";
import { Link } from "react-router-dom";

interface props {
  transparent: boolean;
}

const Nav: React.FC<props> = (props) => {
  return (
    <div className={props.transparent ? "nav navT" : "nav"}>
      <Link to={"/"} className="logo">
        <img src={require("../../Assets/logo3.png")} alt="Logo" />
      </Link>
      <div className="links">
        <NavItem name="Home" paths={["/", "/home"]} />
        <NavItem name="Plan" paths={["/planyourtour"]} />
        <NavItem name="Team" paths={["/team"]} />
        <NavItem name="About" paths={["/about"]} />
      </div>
    </div>
  );
};

export default Nav;
