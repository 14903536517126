import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "../Css/Venus.css";
import Nav from "../Components/Layout/Nav";

const Venus = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  useEffect(() => {
    if (id) {
      document.getElementById(id)?.scrollIntoView();
    }
  }, [id]);
  return (
    <div className="venus">
      {/* nav  */}
      <Nav transparent={true} />

      {/* head  */}
      <div className="header">
        <div>
          <div className="text">VENUS</div>
          <div className="desc">A fiery inferno, where lead would turn to liquid.</div>
        </div>
        <img src={require("../Assets/venus/venus.png")} alt="Venus" />
      </div>

      {/* tourist destination  */}
      <div className="aboutHeader">ABOUT VENUS</div>
      <div className="aboutDesc">
        <div>
          Venus, a captivating and enigmatic planet. Cloaked in a shroud of ethereal clouds, it bears the name of a goddess of love, evoking a sense of beauty and allure. However,
          as one ventures closer, the true nature of Venus is revealed, transforming into a fiery inferno. Its scorching surface boasts temperatures capable of liquefying lead,
          rendering it an inhospitable realm. The atmosphere, dense and suffocating, obscures the Sun's radiant glow, reducing it to a mere smudge of light. Venus, a paradoxical
          world of contrasting elements, beckons us to unravel its mysteries and marvel at the wonders of our cosmic neighborhood.
        </div>
        <iframe src="https://eyes.nasa.gov/apps/orrery/#/venus" title="Venus" />
      </div>

      {/* tourist destination  */}
      <div className="touristHeader">TOURISM IN VENUS</div>
      <div className="touristDesc">
        <img src={require("../Assets/venus/image2.png")} alt="Venus Surface" />
        <div>
          Tourism on Venus remains a distant dream, confined to the pages of science fiction. Despite its similarities to Earth, Venus is a hostile planet with scorching
          temperatures, crushing pressure, and a toxic atmosphere. Its surface is perpetually hidden beneath thick clouds, denying tourists any chance of witnessing its natural
          wonders. The challenges of landing and surviving on Venus make it an improbable destination for human tourism, with exploration efforts instead focused on robotic
          missions.
        </div>
      </div>

      {/* facts  */}
      <div className="factsHeader">
        <div>FACTS ABOUT VENUS</div>
      </div>
      <div className="factsDesc">
        <div>
          <div>
            <li>
              A day on Venus (the time it takes to complete one rotation) is longer than its year (the time it takes to orbit the Sun). It takes about 243 Earth days for Venus to
              complete one rotation on its axis, while it only takes about 225 Earth days for it to orbit the Sun.
            </li>
            <li>
              Venus has one of the most extreme surface temperatures in the solar system. Due to its thick atmosphere that traps heat, temperatures can reach up to 900 degrees
              Fahrenheit (475 degrees Celsius), hotter than the surface of Mercury, despite Venus being further from the Sun.
            </li>
            <li>
              Venus is often referred to as Earth's "sister planet" because it's similar in size and composition. It's only slightly smaller than Earth, with a diameter of about
              12,104 kilometers (7,521 miles).
            </li>
          </div>
        </div>
        <img src={require("../Assets/venus/volcano.png")} alt="Volcano" />
      </div>
    </div>
  );
};

export default Venus;
