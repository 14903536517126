import React from "react";
import Member from "../Components/Team/Member";
import "../Css/Team.css";

const Team = () => {
  return (
    <div className="team">
      <div className="container">
        <div className="text">Our Team</div>
        <div className="members">
          <Member image={require("../Assets/pfp/abishek.png")} name="Abishek Parajuli" username="abishekparajuli_17" role="Team Leader" />
          <Member image={require("../Assets/pfp/prince.png")} name="Prince Mandal" username="mndl_prince" role="Researcher" />
          <Member image={require("../Assets/pfp/aayush.png")} name="Aayush Mainali" username="aayushmainali.67" role="Web Developer" />
          <Member image={require("../Assets/pfp/sirjan.png")} name="srijan0777" username="srijan0777" role="Researcher" />
          <Member image={require("../Assets/pfp/apekxya.png")} name="Apekshya Dhungana" username="apekshya.dhungana" role="Guardain" />
          <Member image={require("../Assets/pfp/sivika.jpg")} name="Shivika Devkota" username="shivikee" role="Member" />
        </div>
      </div>
    </div>
  );
};

export default Team;
