import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import "../Css/Moon.css";
import Nav from "../Components/Layout/Nav";

const Moon = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const id = query.get("id");

  useEffect(() => {
    if (id) {
      document.getElementById(id)?.scrollIntoView();
    }
  }, [id]);
  return (
    <div className="moon">
      {/* nav  */}
      <Nav transparent={true} />

      {/* head  */}
      <div className="header">
        <div className="text">MOON</div>
        <img src={require("../Assets/moon/moon.png")} alt="Moon" />
      </div>

      {/* short desc  */}
      <div className="shortdesc">
        <div>
          The place where the boundaries of human exploration and imagination blend together, offering an extraordinary experience that surpasses the wildest dreams of adventurous
          souls.
        </div>
      </div>

      {/* tourist destination  */}
      <div className="touristHeader">
        <img src={require("../Assets/moon/moon.png")} alt="Moon" />
        <div>WHY MOON AS TOURIST DESTINATION?</div>
      </div>
      <div className="touristDesc">
        <div>
          In the era where many wonders from around the space has been discovered and extensively explored from many prospective. The desire of humans to explore and enjoy many new
          mystery and adventure is natural. So the most suitable, efficient and ultimate frontier for fulfilling their thrive can be the Moon. The brightest and largest object in
          our night sky, the Moon can fulfill every child dreams of floating and enjoying in its rugged terrain . A new era of tourism has emerged, where daring travelers exchange
          their earthly confines for the captivating allure of the lunar landscape .
        </div>
        <iframe src="https://eyes.nasa.gov/apps/solar-system/#/moon" title="Earth" />
      </div>

      {/* facts  */}
      <div className="factsHeader">
        <img src={require("../Assets/moon/moon.png")} alt="Moon" />
        <div>SOME FACTS ON MOON</div>
      </div>
      <div className="factsDesc">
        <img src={require("../Assets/moon/moon2.png")} alt="Moon" />
        <div>
          The brightest and largest object in our night sky, the Moon makes Earth a more livable planet by moderating our home planet's wobble on its axis, leading to a relatively
          stable climate. It also causes tides, creating a rhythm that has guided humans for thousands of years. The Moon was likely formed after a Mars-sized body collided with
          Earth several billion years ago. Earth's only natural satellite is simply called "the Moon" because people didn't know other moons existed until Galileo Galilei
          discovered four moons orbiting Jupiter in 1610. In Latin, the Moon was called Luna, which is the main adjective for all things Moon-related: lunar.
        </div>
      </div>

      {/* water  */}
      <div className="waterHeader">
        <img src={require("../Assets/moon/moon.png")} alt="Moon" />
        <div>WATER ON MOON</div>
      </div>
      <div className="waterDesc">
        <img src={require("../Assets/moon/water.jpg")} alt="Moon" />
        <div>
          Scientists also found the lunar surface releases its water when the Moon is bombarded by micrometeoroids. The surface is protected by a layer, a few centimeters of dry
          soil that can only be breached by large micrometeoroids. When micrometeoroids impact the surface of the Moon, most of the material in the crater is vaporized. The shock
          wave carries enough energy to release the water that's coating the grains of the soil. Most of that water is released into space.
        </div>
      </div>

      {/* weather  */}
      <div className="weatherHeader">
        <img src={require("../Assets/moon/moon.png")} alt="Moon" />
        <div>WEATHER ON MOON</div>
      </div>
      <div className="weatherDesc">
        <img src={require("../Assets/moon/weather.png")} alt="Moon" />
        <div>
          If you are wondering about moons weather then know this that on the Moon, snow does not fall. Thunder never rolls. No clouds form in the pitch-black sky. “Weather” on the
          Moon means something completely different than it does on Earth. The lunar climate is dominated by temperature swings of hundreds of degrees, incoming space rocks of all
          sizes, and particles and energy traveling from the Sun and beyond.
        </div>
      </div>

      {/* carnival  */}
      <div className="carnivalHeader" id="carnival">
        <img src={require("../Assets/moon/moon.png")} alt="Moon" />
        <div>CARNIVAL ON MOON</div>
      </div>
      <div className="carnivalDesc">
        <div>
          The Lunar Carnival would feature a variety of attractions and activities for visitors to enjoy. One of the main highlights would be a breathtaking lunar roller coaster
          that takes riders on a thrilling journey through the moon's craters and valleys. The coaster would be designed to simulate the feeling of weightlessness, giving riders a
          taste of what it's like to float in space. To satisfy the adventurous souls, the Lunar Carnival would also offer moon bungee jumping. Participants would be able to leap
          off a platform and experience the exhilarating sensation of bouncing and soaring in the moon's low gravity. To satisfy the adventurous souls, the Lunar Carnival would
          also offer moon bungee jumping. Participants would be able to leap off a platform and experience the exhilarating sensation of bouncing and soaring in the moon low
          gravity
        </div>
        <img src={require("../Assets/moon/carnival.png")} alt="Moon" />
      </div>

      {/* dyk  */}
      <div className="dykHeader">
        <div>DID YOU KNOW??</div>
        <img src={require("../Assets/moon/moon.png")} alt="Moon" />
      </div>
      <div className="dykDesc">
        <div>
          <li>
            The Moon is farther away from Earth than people often think. A good ballpark number to remember is that the Moon is about a quarter of a million miles away (that is,
            250,000 miles), or about 400 thousand kilometers. It's such a vast distance that you could just about fit the other seven major planets into the space between the two
            worlds.
          </li>
          <li>
            The Moon tends to look sort of orange or amber colored when it's rising or setting, and more whitish when it's high overhead. But it might surprise you that it looks
            different up close. The orangey colors are caused by the Moon's light passing through Earth's atmosphere to reach your eyes. And the bright whitish appearance (would
            call it "eggshell," or maybe "bone"?) results from its surface being brightly illuminated by the Sun. (The Moon doesn't emit its own light - it reflects light from the
            Sun.)
          </li>
        </div>
      </div>
    </div>
  );
};

export default Moon;
